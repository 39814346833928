import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.css']
})
export class InsightsComponent implements OnInit {
  introP: Object;
  introSupP: Object;
  section: 0 | 1 | 2;

  
  constructor(private data: DataService, private route: ActivatedRoute) { }
 
  ngOnInit() {

    let param = this.route.snapshot.queryParams["param"];
    if(param=="articles"){
      this.section=0;
    }
    if(param=="downloads"){
      this.section=1;
    }
    if(param=="videos"){
      this.section=2;
    }
    console.log(this.section)

    this.data.getInsightsParagraph().subscribe(data => {
        this.introP = data['data'][0].description;
        this.introSupP = data['data'][0].title;
      }
    ); 

  
  }


}
