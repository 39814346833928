import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-responsible-investing',
  templateUrl: './responsible-investing.component.html',
  styleUrls: ['./responsible-investing.component.css']
})
export class ResponsibleInvestingComponent implements OnInit {
  introMP: Object;
  introSubMP: Object;
  introP: Object;
  introSubP:Object;
  CSIPara : Object;
  AcademyPara : Object;
  TransformationPara : Object;
  InvestmentPara : Object;  

  constructor(private data: DataService, private router: Router) { }

  ngOnInit(): void {
    this.data.getResponsibleInvestingParagraph().subscribe(data => {
      this.introMP =data['data'][0].description;
      this.introSubMP =data['data'][0].title;
    }
  ); 
   
    this.data.getInvestGoodParagraph().subscribe(data => {
      var count = data['data'].length;
      var title = "";
        for (let i = 0; i < count; i++){
          title = data['data'][i].title;
          switch (title) {
            case "INVEST FOR GOOD":
              this.introP = data['data'][i].description;
              break;
            case "Mentorship Programme":
              this.AcademyPara = data['data'][i].description;
              break;
            case "Transformation":
              this.TransformationPara = data['data'][i].description;
              break
            case "Investments":
              this.InvestmentPara = data['data'][i].description;
              break
            case "CSI":
              this.CSIPara = data['data'][i].description;
              break;
          }
        }     
       }
      )
  }
}
